import React from "react"

import { Container, Row, Col, Hidden, Visible } from "react-grid-system"

import * as styles from "./integrations.module.scss"

import * as imgOptima from "../../images/integrations/optima.png"
import * as imgWapro from "../../images/integrations/wapro.png"
import * as imgInsert from "../../images/integrations/insert.png"
import * as imgSage from "../../images/integrations/sage.png"
import * as imgRaks from "../../images/integrations/raks.png"

const Integrations = () => (
  <section id="integrations">
    <div className={styles.main}>
      <h1>Integracje</h1>
      <div className={styles.subheader}>
        Z&nbsp;jakimi&nbsp;programami księgowymi&nbsp;współpracujemy?
      </div>

      <Visible xs sm md>
        <div style={{ padding: "0 5%" }}>
          <img src={imgOptima} alt="Comarch Optima ERP" />
        </div>
        <div className={styles.plannedSmall}>Planujemy także:</div>
        <Container fluid>
          <Row justify="center">
            <Col xs={6} md={3} className={styles.logoBox}>
              <img src={imgInsert} alt="INSERT" />
            </Col>
            <Col xs={6} md={3}>
              <img src={imgWapro} alt="Asseco WAPRO" />
            </Col>
            <Col xs={6} md={3}>
              <img src={imgSage} alt="Sage Symfonia" />
            </Col>
            <Col xs={6} md={3}>
              <img src={imgRaks} alt="RaksSQL" />
            </Col>
          </Row>
        </Container>
      </Visible>

      <Hidden xs sm md>
        <Container fluid>
          <Row justify="center">
            <Col lg={2} className={styles.logoBox}>
              <img src={imgInsert} alt="INSERT" />
              <div className={styles.planned}>w planach</div>
            </Col>
            <Col lg={2}>
              <img src={imgWapro} alt="Asseco WAPRO" />
              <div className={styles.planned}>w planach</div>
            </Col>
            <Col lg={3} className={styles.centerLogo}>
              <img src={imgOptima} alt="Comarch Optima ERP" />
            </Col>
            <Col lg={2}>
              <img src={imgSage} alt="Sage Symfonia" />
              <div className={styles.planned}>w planach</div>
            </Col>
            <Col lg={2}>
              <img src={imgRaks} alt="RaksSQL" />
              <div className={styles.planned}>w planach</div>
            </Col>
          </Row>
        </Container>
      </Hidden>
    </div>
  </section>
)

export default Integrations
