import React, { useState } from "react"
import { Container, Row, Col } from "react-grid-system"
import { useSpring, animated } from "react-spring"

import Image from "../image"

import styles from "./splash.module.scss"

const config = { mass: 5, tension: 2000, friction: 200 }

const profits = [
  "Zmniejsz koszty",
  "Popraw wydajność",
  "Zaoszczędź czas",
  "Zwiększ rentowność",
  "Ogranicz pomyłki",
]

const Splash = () => {
  const [hide, setHide] = useState(true)
  const [trigger, setTrigger] = useState(true)
  const [profitIdx, setProfitIdx] = useState(0)

  const { x, height, ...rest } = useSpring({
    config,
    opacity: hide ? 1 : 0,
    x: hide ? 0 : 20,
    height: hide ? 60 : 0,
    from: { opacity: 0, x: 20, height: 0 },
    onFrame: f => {
      if (!hide && f.opacity < 0.1) {
        setHide(true)
        setTrigger(true)
        setProfitIdx(profitIdx + 1 === profits.length ? 0 : profitIdx + 1)
      } else if (trigger & (f.opacity === 1)) {
        setTrigger(false)
        setTimeout(() => setHide(false), 2000)
      }
    },
  })

  return (
    <section id="masthead">
      <div className={styles.main}>
        <Container>
          <Row>
            <Col lg={5} className={styles.profitsCol}>
              <div className={styles.profitsMain}>
                <div>
                  <animated.h1
                    className={styles.profitsText}
                    style={{
                      ...rest,
                      transform: x.interpolate(x => `translate3d(0,${x}px,0)`),
                    }}
                  >
                    <animated.div style={{ height }}>
                      {profits[profitIdx]}
                    </animated.div>
                  </animated.h1>
                </div>
              </div>
            </Col>
            <Col lg={7} className={styles.profitsRestCol}>
              <h1>Twojego biura rachunkowego</h1>
            </Col>
          </Row>
          <Row>
            <Col sm={12}>
              <p className={styles.subheadline}>
                Engrafa ułatwi Ci pracę w biurze rachunkowym. Pomoże Ci
                automatycznie czytać skany i&nbsp;faktury elektroniczne oraz
                doda je do Twojego programu księgowego. Da Ci też intuicyjne
                narzędzia pomagające w&nbsp;codziennej pracy w Twojej firmie.
              </p>

              <div className={styles.preview}>
                <div style={{ maxWidth: 1200 }}>
                  <Image />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </section>
  )
}

export default Splash
