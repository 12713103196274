import React, { useState } from "react"

import * as styles from "./subscribe.module.scss"
import useFirebase from "../../firebase"

const validateEmail = email => {
  // eslint-disable-next-line
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

const Subscribe = () => {
  const [email, setEmail] = useState("")
  const [submitting, setSubmitting] = useState(false)
  const [errorMsg, setErrorMsg] = useState("")
  const [submitted, setSubmitted] = useState(false)
  const fb = useFirebase()

  return (
    <section id="subscribe" style={{ marginTop: -100 }}>
      <div className={styles.triangleWrapper}>
        <svg
          height="100px"
          width="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon
            points="0,0 100,100 0,100"
            style={{ fill: "#7c6adf", strokeWidth: 0 }}
          />
        </svg>
      </div>
      <div className={styles.main}>
        <div className={styles.wrapper}>
          <h1>Premiera wkrótce!</h1>
          <div className={styles.whitebox}>
            <div style={{ display: !submitted ? "none" : "block" }}>
              <h2>Gotowe! Będziemy w kontakcie!</h2>
              <div className={styles.checkmark}>
                <svg
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 130.2 130.2"
                  width="80px"
                >
                  <circle
                    className="path circle"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeMiterlimit="10"
                    cx="65.1"
                    cy="65.1"
                    r="62.1"
                  />
                  <polyline
                    className="path check"
                    fill="none"
                    stroke="#73AF55"
                    strokeWidth="6"
                    strokeLinecap="round"
                    strokeMiterlimit="10"
                    points="100.2,40.2 51.5,88.8 29.8,67.5 "
                  />
                </svg>
              </div>
            </div>
            <div style={{ display: submitted ? "none" : "block" }}>
              <h2>Powiadom mnie o premierze</h2>
              <div className={styles.whiteboxSubheader}>
                Podaj swój e-mail, aby otrzymać powiadomienie o premierze
                serwisu
              </div>

              <div className={styles.form}>
                <input
                  disabled={submitting}
                  onChange={ev => setEmail(ev.currentTarget.value)}
                  value={email}
                  type="email"
                  placeholder="Twój firmowy adres e-mail..."
                />
                <button
                  disabled={submitting}
                  onClick={async () => {
                    if (!validateEmail(email)) {
                      setErrorMsg("Podaj prawidłowy adres e-mail")
                      return
                    }

                    if (!fb) {
                      setErrorMsg(
                        "Wystąpił nieoczekiwany błąd. Spróbuj za chwilę."
                      )
                    }

                    setSubmitting(true)
                    try {
                      await fb
                        .firestore()
                        .collection("newsletter")
                        .add({ email, ts: new Date() })
                      setSubmitted(true)
                    } catch (err) {
                      setErrorMsg(
                        "Wystąpił nieoczekiwany błąd. Spróbuj za chwilę."
                      )
                      setSubmitting(false)
                    }
                  }}
                >
                  Wyślij
                </button>

                <div className={styles.error}>
                  {errorMsg || <span>&nbsp;</span>}
                </div>
              </div>
            </div>

            <div className={styles.disclaimer}>
              Twój adres nie będzie przekazany podmiotom trzecim oraz będzie
              wykorzystany jedynie do informowania o postępie prac nad
              produktem. W&nbsp;każdej chwili możliwe jest wycofanie
              subskrypcji.
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Subscribe
