import React, { useState } from "react"

import Slider from "react-slick"

import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import styles from "./how-it-works.module.scss"
import "./how-it-works.scss"

import * as step0 from "../../images/how-it-works/0-collecting.svg"
import * as step1 from "../../images/how-it-works/1-fast-loading.svg"
import * as step2 from "../../images/how-it-works/2-done-checking.svg"
import * as step3 from "../../images/how-it-works/3-transfer-files.svg"
import * as step4 from "../../images/how-it-works/4-designer-life.svg"

const SliderIndex = ({ idx, active }) => {
  const cls =
    styles.sliderIndexInner +
    " " +
    (active ? styles.sliderIndexActive : styles.sliderIndexInactive)
  return (
    <div className={styles.sliderIndex}>
      <div className={cls}></div>
      <div className={styles.sliderIndexIdx}>{idx}</div>
    </div>
  )
}

const HowItWorks = () => {
  const [slideIdx, setSlideIdx] = useState(0)

  return (
    <section id="how-it-works">
      <div className={styles.wrapper}>
        <svg
          height="100px"
          width="100%"
          viewBox="0 0 100 100"
          preserveAspectRatio="none"
        >
          <polygon
            points="0,100 100,100 100,0"
            style={{ fill: "#7c6adf", strokeWidth: 0 }}
          />
        </svg>
        <div className={styles.main}>
          <h1>Jak działa Engrafa?</h1>
          <Slider
            afterChange={idx => setSlideIdx(idx)}
            arrows={false}
            autoplay={true}
            autoplaySpeed={2000}
            centerMode={true}
            centerPadding="22%"
            infinite
            pauseOnHover={false}
            slidesToShow={1}
            slidesToScroll={1}
            speed={500}
          >
            <div style={{ outline: "none" }}>
              <img src={step0} alt="Pudełko z dokumentami" />
              <div className={styles.step}>
                Importujesz skany lub&nbsp;faktury&nbsp;elektroniczne
              </div>
            </div>
            <div>
              <img src={step1} alt="Człowiek siedzący na wskaźniku postępu" />
              <div className={styles.step}>
                Engrafa je automatycznie odczytuje
              </div>
            </div>
            <div>
              <img
                src={step2}
                alt="Kobieta otoczona zweryfikowanymi dokumentami"
              />
              <div className={styles.step}>
                Możesz zweryfikować poprawność&nbsp;odczytu
              </div>
            </div>
            <div>
              <img src={step3} alt="Wymiana dokumentów" />
              <div className={styles.step}>
                Eksportujesz do Twojego programu&nbsp;księgowego
              </div>
            </div>
            <div>
              <img src={step4} alt="Zrelaksowana kobieta przy biurku" />
              <div className={styles.step}>
                Zaoszczędzony czas przeznaczasz na&nbsp;relaks,
                lub&nbsp;jak&nbsp;chcesz ;-)
              </div>
            </div>
          </Slider>
          <div className={styles.sliderIndexContainer}>
            <div styles={{ margin: "0 auto" }}>
              {[1, 2, 3, 4, 5].map((val, idx) => (
                <SliderIndex
                  key={`sldidx-${idx}`}
                  idx={val}
                  active={idx === slideIdx}
                />
              ))}
            </div>
            <div style={{ clear: "both" }}></div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default HowItWorks
