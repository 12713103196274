import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Splash from "../components/index/splash"
import HowItWorks from "../components/index/how-it-works"
import Benefits from "../components/index/benefits"
import Integrations from "../components/index/integrations"
import Subscribe from "../components/index/subscribe"

const IndexPage = props => {
  return (
    <Layout location={props.location.pathname}>
      <SEO title="Strona główna" />
      <Splash />
      <HowItWorks />
      <Benefits />
      <Integrations />
      <Subscribe />
    </Layout>
  )
}

export default IndexPage
