import { useState, useEffect } from "react"

const config = {
  apiKey: "AIzaSyCtuECZqcMMBGqGPZog54STvbjAY51m_jA",
  authDomain: "engrafa-website.firebaseapp.com",
  databaseURL: "https://engrafa-website.firebaseio.com",
  projectId: "engrafa-website",
  storageBucket: "engrafa-website.appspot.com",
  messagingSenderId: "869676580809",
  appId: "1:869676580809:web:84bf8fde3c4cfc4e",
}

const useFirebase = () => {
  const [fb, setFb] = useState(undefined)
  useEffect(() => {
    if (fb !== undefined) {
      return
    }

    const app = import("firebase/app")
    const db = import("firebase/firestore")

    Promise.all([app, db]).then(([app, db]) => {
      if (!app.apps.length) {
        app.initializeApp(config)
      }
      setFb(app)
    })
  })

  return fb
}

export default useFirebase
