import React from "react"
import { Container, Row, Col, Hidden } from "react-grid-system"

import * as imgTimeManagement from "./../../images/benefits/time-management.svg"
import * as imgInvesting from "./../../images/benefits/investing.svg"
import * as imgAllTheData from "./../../images/benefits/all-the-data.svg"
import * as imgSetupWizard from "./../../images/benefits/setup-wizard.svg"

import styles from "./benefits.module.scss"

const Benefits = () => (
  <section id="benefits">
    <div className={styles.wrapper}>
      <svg
        height="60px"
        width="100%"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <polygon
          points="0,0 100,0 100,100"
          style={{ fill: "#7c6adf", strokeWidth: 0 }}
        />
      </svg>
      <div className={styles.main}>
        <Container fluid>
          <Row>
            <Col sm={10} offset={{ lg: 1 }}>
              <div className={styles.headerDivider} />
              <h1>Co zyskasz dzięki Engrafie?</h1>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={4} lg={6} className={styles.imgCol}>
              <Hidden xs sm>
                <div className={styles.imgVerticalHelper} />
                <img
                  src={imgTimeManagement}
                  alt="Kobieta siedząca na wskazówce zegara"
                />
              </Hidden>
            </Col>
            <Col md={8} lg={6} xl={5}>
              <h3>Oszczędzisz czas</h3>
              <div>
                <ul>
                  <li>
                    <strong>
                      Wprowadzaj dokumenty księgowe Twoich klientów nawet
                      kilkadziesiąt razy szybciej
                    </strong>{" "}
                    w porównaniu do tradycyjnych metod. Zaawansowane algorytmy
                    odczytają wszystkie niezbędne informacje z dowolnego
                    szablonu faktur elektronicznych i&nbsp;skanów.
                  </li>
                  <li>
                    Wyszukaj dowolny dokument za pomocą zaawansowanej
                    wyszukiwarki i w kilka sekund prześlij go do klienta.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={8} lg={6} xl={5} offset={{ xl: 1 }}>
              <h3>Zwiększysz rentowność</h3>
              <div>
                <ul>
                  <li>
                    Szybsze wprowadzanie dokumentów przy jednoczesnym obniżeniu
                    ryzyka pomyłek pozwoli Ci obsłużyć{" "}
                    <strong>
                      większą ilość klientów przy tym samym lub mniejszym
                      nakładzie pracy
                    </strong>
                    .
                  </li>
                  <li>
                    Automatyzacja wprowadzania dokumentów pozwoli Ci oddelegować
                    weryfikację ich poprawności do mniej wykwalifikowanych
                    pracowników, a Twoim księgowym pozwoli{" "}
                    <strong>skupić się na ważniejszych zadaniach</strong>.
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              md={4}
              lg={6}
              className={styles.imgCol}
              style={{ textAlign: "right" }}
            >
              <Hidden xs sm>
                <div className={styles.imgVerticalHelper} />
                <img
                  src={imgInvesting}
                  alt="Mężczyzna patrzący na rosnący wykres"
                />
              </Hidden>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={4} lg={6} className={styles.imgCol}>
              <Hidden xs sm>
                <div className={styles.imgVerticalHelper} />
                <img src={imgAllTheData} alt="Mężczyzna oparty o wykresy BI" />
              </Hidden>
            </Col>
            <Col md={8} lg={6} xl={5}>
              <h3>Zwiększysz kontrolę</h3>
              <div>
                <ul>
                  <li>
                    W dowolnym momencie sprawdź, czy Twoi klienci dostarczyli
                    już komplet dokumentów za ostatni miesiąc.
                  </li>
                  <li>
                    <strong>Engrafa pomoże Ci uniknąć częstych pomyłek</strong>{" "}
                    &mdash; podpowie, jakich faktur cyklicznych brakuje w
                    komplecie dokumentów Twojego klienta, jakie dokumenty
                    zostały dodane podwójnie, itp.
                  </li>
                  <li>
                    Będziesz mieć łatwy podgląd historii modyfikacji dowolnego
                    dokumentu i historii wykonanych działań Twoich pracowników.
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
          <Row className={styles.row}>
            <Col md={8} lg={6} xl={5} offset={{ xl: 1 }}>
              <h3>Łatwa integracja</h3>
              <div>
                <ul>
                  <li>
                    <strong>
                      Integracja z programami księgowymi jest banalnie prosta
                    </strong>
                    , nie będziesz musieć angażować w to Twojego informatyka lub
                    pomocy technicznej.
                  </li>
                  <li>
                    Niepubliczna wersja Engrafy jest już w użytku od wielu lat
                    &mdash; pozwoliło to na dopracowanie intuicyjnego
                    interfejsu, którego opanowanie zajmuje tylko kilka chwil i
                    nie wymaga specjalnych szkoleń.
                  </li>
                </ul>
              </div>
            </Col>
            <Col
              md={4}
              lg={6}
              className={styles.imgCol}
              style={{ textAlign: "right" }}
            >
              <Hidden xs sm>
                <div className={styles.imgVerticalHelper} />
                <img src={imgSetupWizard} alt="Kobieta konfigurująca program" />
              </Hidden>
            </Col>
          </Row>
          {/*
          <Row>
            <Col sm={12}>
              <div className={styles.tryOut}>
                <Link to="/rejestracja">Wypróbuj za darmo</Link>
              </div>
            </Col>
          </Row>
          */}
        </Container>
      </div>
    </div>
  </section>
)

export default Benefits
